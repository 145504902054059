.ps-home-slider-container {
  .ps-home-slider-single {
    position: relative;
    height: 250px;
    .slider-shadow {
      box-shadow: inset -300px 40px 200px 0 rgba(0, 0, 0, 0.75),
        0 0 15px 5px rgba(0, 0, 0, 0.8);
    }
    .slider-background {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 1;
      background: #222;
      overflow: hidden;

      filter: blur(20px);
      background: #666;

      img {
        transform-style: preserve-3d;
      }
    }
    .slider-content {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 3;
      // padding: 25px 20px;
      padding: 39px 20px;
      text-align: left;

      .manga-title {
        font-size: 16.5px;
        // line-height: 26px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        a {
          color: #fff;
        }
      }
      // .manga-poster {
      //   img {
      //     height: 155px;
      //   }
      // }
      .manga-main-genre {
        color: $color-yellow;
      }
      .manga-summary {
        &-title {
          font-size: 12px;
          font-weight: 700;
          color: #ccc;
        }
        &-content {
          font-size: 12px;
        }
      }
      .manga-genres {
        position: absolute;
        bottom: 0;
        max-width: 95%;
        overflow-x: scroll;
        // right: 0;
        .badge {
          background-color: #a5a5a5;
        }
      }
    }
  }
}

.ps-home-latest-container {
  .card {
    background: $color-grey-black;
    padding: 5px;
  }

  .ps-home-latest-header {
    border-bottom: 1px solid #312f40;
  }
}

.ps-single-manga {
  &--img {
    position: relative;
    // img {
    //   filter: blur(0.8px);
    // }

    .ch-genre {
      position: absolute;
      top: 2px;
      left: 2px;
      background: rgba($color-yellow, 0.79);
      font-size: 10px;
      color: #fff;
      padding: 3px 4px 3px 5px;
      border-radius: 3px 3px;
    }

    .ch-single {
      color: #fff;
      background-color: rgba(0, 0, 0, 0.7);
      padding: 4.5px 7px;
      border-radius: 3.5px;
      font-size: 13px;
      width: 98%;

      position: absolute;
      left: 2px;

      &:hover {
        text-decoration: none;
        color: $color-red;
      }

      i {
        font-size: 17px;
      }

      .left {
        font-weight: 700;
      }

      .right {
        font-size: 10px;
        padding-top: 2px;
      }
    }

    .ch-one {
      bottom: 8px;
    }

    .ch-two {
      bottom: 41px;
    }

    .ch-three {
      bottom: 75px;
    }
  }
  &--title {
    a {
      color: $color-white;
      font-weight: 500;
      font-size: 12px;

      // max-width: 55%;
      // overflow: hidden;
      // white-space: nowrap;
      // text-overflow: ellipsis;

      &:hover {
        text-decoration: none;
        color: $color-red;
      }
    }
  }

  &--footer {
    color: #838aa0 !important;
    font-size: 10px;
  }
}
// .ps-single-manga {
//   &--img {
//     position: relative;

//     // img {
//     //   width: 300px;
//     //   height: 400px;
//     // }

//     .ch-single {
//       color: #fff;
//       background-color: rgba(0, 0, 0, 0.7);
//       padding: 4.5px 7px;
//       border-radius: 3.5px;
//       font-size: 13px;
//       width: 98%;

//       position: absolute;
//       left: 2px;

//       &:hover {
//         text-decoration: none;
//         color: $color-red;
//       }

//       i {
//         font-size: 17px;
//       }

//       .left {
//         font-weight: 700;
//       }

//       .right {
//         font-size: 10px;
//         padding-top: 2px;
//       }
//     }

//     .ch-one {
//       bottom: 8px;
//     }

//     .ch-two {
//       bottom: 41px;
//     }

//     .ch-three {
//       bottom: 75px;
//     }
//   }
//   &--title {
//     a {
//       color: $color-white;
//       font-weight: 500;
//       font-size: 13px;

//       &:hover {
//         text-decoration: none;
//         color: $color-red;
//       }
//     }
//   }

//   &--footer {
//     color: #838aa0 !important;
//     font-size: 10px;
//   }
// }
