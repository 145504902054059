@import "fonts";
//-- Variables
$font-primary: $font-open-sans;
$font-logo: $font-primary;

$color-white: #fff;
$color-black: #000;
$color-yellow: #ffc107;
$color-grey-black: #222;
$color-red: #e50815;
:root {
  --bs-body-color: $color-black;
  --bs-body-bg: $color-black;
  //   --bs-card-border-color: #434968;
  //       --bs-card-bg: #2f3349;
}

body {
  font-family: $font-primary;
  background: $color-black;
}

@import "nav";
@import "home";
