.ps-navbar-container {
  a {
    color: white;
  }
  .ps-navbar-brand {
    img {
      height: 50px;
    }
    .brand-name {
      font-family: $font-logo;
      font-weight: bold;
      font-size: 18px;
    }
  }
  .ps-default-avatar {
    height: 20px;
  }
  .ps-navbar-link-container {
    a {
      color: white;
    }
  }
}
